<template>
  <div class="create-cite" @click="close()">
    <div class="parent-cite-form" @click.stop="">
      <b-overlay :show="overlay" class="cite-form">
        <div class="header-form">
          <span v-if="!citeData.nomCite">
            {{ $t("data.cite_ajouter_cite") }}
          </span>
          <span v-else>
            {{ $t("data.cite_editer_cite") }}
          </span>
          <div class="close-icon">
            <i class="ik ik-x clos" @click="close()"></i>
          </div>
        </div>
        <div class="main-form">
          <div class="main-left">
            <div class="input-item">
              <span>Nom de la cité</span>
              <input
                type="text"
                placeholder="Ex: Cité des Glycines"
                v-model="nom"
              />
            </div>
            <div class="input-item">
              <span>Reférence de la cité</span>
              <input type="text" placeholder="Ex: CDG" v-model="ref" />
            </div>
            <div class="input-item">
              <span>Superficie de la cité (m²)</span>
              <input
                type="number"
                min="0"
                placeholder="Entrez la superficie totale"
                v-model="superficie"
              />
            </div>
          </div>
          <div class="main-right">
            <div class="input-item">
              <span @click="evtrans()">Photos de la cité</span>
              <div class="img-block">
                <label>
                  <input
                    type="file"
                    accept="image/*"
                    ref="imageCite"
                    @change="onFile"
                  />
                </label>
                <!-- <img :src="photo" v-if="photo" alt="image de la cité" /> -->
                <img v-if="photo" :src="photo" alt="image de la cité" />
                <div class="placeholder-img" v-else>
                  <i class="ik ik-image"></i>
                  <span> Veuillez choisir une image. </span>
                </div>
              </div>
            </div>
            <div class="img-group">
              <div>
                <img :src="photo" v-if="photo" alt="image cite" />
              </div>
              <div>
                <img :src="photo" v-if="photo" alt="image cite" />
              </div>
              <div>
                <img :src="photo" v-if="photo" alt="image cite" />
              </div>
              <div>
                <img :src="photo" v-if="photo" alt="image cite" />
              </div>
              <div>
                <img :src="photo" v-if="photo" alt="image cite" />
              </div>
            </div>
          </div>
        </div>
        <div class="footer-form">
          <div class="cancel" @click="close()">
            <OutlinedDarkBtn buttonTitle="Annuler" />
          </div>
          <div v-if="!citeData.nomCite" class="validate" @click="submitModal('add')">
            <FullPinkBtn buttonTitle="Créer la cité" />
          </div>
          <div v-else class="validate" @click="submitModal('edit')">
            <FullPinkBtn buttonTitle="Editer la cité" />
          </div>
        </div>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import FullPinkBtn from "../atomic/fullPinkBtn.vue";
import OutlinedDarkBtn from "../atomic/outlinedDarkBtn.vue";
const php = require("phpjs");
export default {
  components: { FullPinkBtn, OutlinedDarkBtn },
  props: {
    citeData: { type: Object, required: true },
  },
  data() {
    return {
      photo: "",
      nom: "",
      photo: "",
      ref: "",
      superficie: "",
      id: "",
      overlay: false,
    };
  },
  watch: {
    citeData: {
      handler(newValue, oldValue) {
        this.nom = this.citeData.nomCite;
        this.superficie = this.citeData.superficie;
        this.id = this.citeData.idCite;
        this.ref = this.citeData.refCite;
        this.photo = this.citeData.image;
      },
      deep: true,
    },
  },
  methods: {
    onFile(e) {
      const files = e.target.files;
      if (!files.length) return;
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => (this.photo = reader.result);
      console.log(this.photo);
    },
    close() {
      this.overlay = false;
      this.reset();
      this.$emit("close");
    },
    reset() {
      this.photo = this.nom = this.ref = this.id = this.superficie = "";
    },
    evtrans() {
      let kev = "kevin";
      this.$emit("update", kev);
    },
    async submitModal(action) {
      if (php.empty(this.nom) || php.empty(this.ref)) {
        return App.error("Veuillez remplir tous les champs du formulaire");
      }
      let modal = {
        nom: this.nom,
        ref: this.ref,
        idCite: this.id,
        photo: this.photo,
        superficie: this.superficie,
      };
      const data = Object.assign({}, modal, {
        photo: await this.$getBase64(modal.photo),
        // photo: this.fileToBase64(modal.photo),

      });
      console.log("Photo de la cité", data.photo);
      this.overlay = true;
      if (action == "add") {
        axios
          .post("cites", data)
          .then((response) => {
            if (!response.success) {
              return App.alertError(response.message);
            }
            this.$emit("update", response.result);
            this.close();
            return App.notifySuccess(response.message);
          })
          .catch((error) => {
            this.close();
            return App.alertError(error.message);
          });
      }
      if (action == "edit") {
        axios
          .put(`cites/${this.id}`, data)
          .then((response) => {
            if (!response.success) {
              return App.alertError(response.message);
            }
            this.close();
            return App.notifySuccess(response.message);
          })
          .catch((error) => {
            this.modal.submitted = false;
            return App.alertError(error.message);
          });
      }
    },
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        // Vérifie si le paramètre est bien un objet File
        if (!(file instanceof File)) {
          reject(new Error('Le paramètre doit être un objet File.'));
          return;
        }

        const reader = new FileReader();

        // Fonction appelée lorsque la lecture du fichier est terminée
        reader.onloadend = () => {
          // La propriété result contient les données en Base64
          resolve(reader.result);
        };

        // Fonction appelée en cas d'erreur
        reader.onerror = () => {
          reject(new Error('Erreur lors de la lecture du fichier.'));
        };

        // Lire le fichier en tant que Data URL (Base64)
        reader.readAsDataURL(file);
      })
    }
  },
  mounted() {
    this.nom = this.citeData.nomCite;
    this.superficie = this.citeData.superficie;
    this.ref = this.citeData.refCite;
    this.ref = this.citeData.Image;
    let googleScript = document.createElement("script");
    googleScript.setAttribute(
      "src",
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyB4wnGV-GeVMiv-_yT2qO_iKyaM_bMGX7w&libraries=places"
    );
    document.head.appendChild(googleScript);
  },
};
</script>

<style scoped>
.create-cite {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  animation: appear2 0.35s;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  z-index: 1500;
  cursor: pointer;
}
@keyframes appear2 {
  0% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.create-cite .parent-cite-form {
  height: auto;
  width: 50%;
  max-height: 700px;
  background: white;
  border-radius: 8px;
}
.parent-cite-form .cite-form {
  height: auto;
  width: 100%;
  border-radius: 8px;
}
.cite-form .header-form {
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10%;
  min-height: 70px;
  border-bottom: 1px solid #eee;
}
.header-form span {
  font-size: 1.4rem;
  font-weight: bold;
}
.header-form .close-icon {
  font-size: 1.5rem;
  color: #191c22;
  cursor: pointer;
}
.cite-form .main-form {
  padding: 30px 5%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5%;
  width: 100%;
  height: auto;
}
.main-form .main-left {
  grid-area: 1 / 1 / 2 / 2;
}
.main-form .main-right {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  flex-direction: column;
}
.main-right .img-group {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 10px;
  width: 100%;
  height: 60px;
  display: none;
}
.img-group div {
  background: rgb(235, 235, 235);
  border-radius: 8px;
}
.img-group div img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.img-group div:nth-child(1) {
  grid-area: 1 / 1 / 2 / 2;
}
.img-group div:nth-child(2) {
  grid-area: 1 / 2 / 2 / 3;
}
.img-group div:nth-child(3) {
  grid-area: 1 / 3 / 2 / 4;
}
.img-group div:nth-child(4) {
  grid-area: 1 / 4 / 2 / 5;
}
.img-group div:nth-child(5) {
  grid-area: 1 / 5 / 2 / 6;
}
.main-form .input-item {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}
.input-item > span {
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 500;
}
.input-item input:not([type="image"]) {
  padding: 0 5px;
  height: 45px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
  outline: none;
  background: transparent;
}
.input-item input::placeholder {
  font-size: 0.85rem;
}
.input-item .img-block {
  margin-top: 5px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-item .img-block img {
  display: inline-block;
  height: 140px;
  width: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.input-item .img-block label {
  position: absolute;
  top: 0;
  left: 0;
  height: 140px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
}
.input-item .img-block label input {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: transparent;
  display: none;
}
.input-item .img-block .placeholder-img {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 100%;
}
.placeholder-img i {
  font-size: 1.8rem;
}
.cite-form .footer-form {
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10%;
  min-height: 70px;
  border-top: 1px solid #eee;
}
</style>
