<template>
  <div class="list-item list-item-grid">
    <!-- <div class="card d-flex flex-row mb-3">
            <a v-if="canSeeDetailsCite" class="d-flex card-img" @click.prevent="showDetails" href="#">
                <img :src="cite.image || `${$router.options.base}img/bgCity.jpg`" alt="" style="height: 10em" class="list-thumbnail responsive border-0">
                <span v-if="isNew" class="badge badge-pill badge-primary position-absolute badge-top-left">{{$t('data.cite_new')}}</span>
            </a>
            <a v-else class="d-flex card-img" href="#">
                <img :src="cite.image || `${$router.options.base}img/bgCity.jpg`" alt="" style="height: 10em" class="list-thumbnail responsive border-0">
                <span v-if="isNew" class="badge badge-pill badge-primary position-absolute badge-top-left">{{$t('data.cite_new')}}</span>
            </a>
            <div class="d-flex flex-grow-1 min-width-zero card-content">
                <div class="card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
                    <div class="text-center">
                        <h4 class="text-red mt-0 truncate">{{ cite.nomCite }}</h4>
                        <span class="d-inline-block text-muted">{{$t('data.cite_reference_cite')}}: <b>{{ cite.refCite }}</b></span>
                    </div>
                    <p v-b-tooltip.bottom="dateCreation" class="mt-3 mb-1 text-muted text-small date w-15 w-xs-100">{{ $date(cite.createdAt).format("DD.MM.YYYY") }}</p>
                </div>
                <div class="list-actions">
                    <a v-if="canSeeDetailsCite" href="#" @click.prevent="showDetails" v-b-tooltip.bottom="$t('data.cite_tooltip_details')"><i class="ik ik-eye"></i></a>
                    <a v-if="canUpdateCite" href="#" @click.prevent="$emit('makeUpdate', cite)" v-b-tooltip.bottom="$t('data.cite_tooltip_editer')"><i class="ik ik-edit-2"></i></a>
                    <a v-if="canDeleteCite" href="#" @click.prevent="remove" class="list-delete" v-b-tooltip.bottom="$t('data.cite_tooltip_supprimer')"><i class="ik ik-trash-2"></i></a>
                </div>
            </div>
            <div class="nombre-cite text-white px-2">
                <span class="font-weight-bold h4">{{ cite.batiments.length }}</span>
                <span v-if="cite.batiments.length > 1">
                    {{ $t('data.logement_batiment_lie_au_clonage_label') }}s
                </span>
                <span v-else>
                    {{ $t('data.logement_batiment_lie_au_clonage_label') }}
                </span>
            </div>
        </div> -->
    <div class="cite-item">
      <div class="cite-img" @click.prevent="showDetails">
        <img :src="cite.image" alt="image d'une cité" />
        <div class="nbr-batiment">
          22.01.2023
        </div>
      </div>
      <div class="cite-info">
        <div class="txt">
          <span class="cite-title">
            {{ cite.nomCite }}
          </span>
          <span class="cite-ref">
            Réference : <b>{{ cite.refCite }}</b>
          </span>
        </div>
        <div class="cite-action">
          <span class="possession">
            Possède
            <b
              >{{ cite.batiments.length }} batiment<b
                v-show="cite.batiments.length > 1"
                >s</b
              >
            </b>
          </span>
          <div class="btns">
            <button
              v-if="canSeeDetailsCite"
              @click.prevent="showDetails"
              v-b-tooltip.bottom="$t('data.cite_tooltip_details')"
            >
              <i class="ik ik-eye"></i>
            </button>
            <div class="barre"></div>
            <button
              v-if="canUpdateCite"
              @click.prevent="$emit('makeUpdate', cite)"
              v-b-tooltip.bottom="$t('data.cite_tooltip_editer')"
            >
              <i class="ik ik-edit-2"></i>
            </button>
            <div class="barre"></div>
            <button
              v-if="canDeleteCite"
              @click.prevent="remove"
              v-b-tooltip.bottom="$t('data.cite_tooltip_supprimer')"
            >
              <i class="ik ik-trash-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    cite: { type: Object, required: true },
    isSub: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters(["hasAccess"]),
    dateCreation() {
      const day = this.$date(this.cite.createdAt);
      return `Créée le ${day.format("dddd, DD MMMM YYYY")} à ${day.format(
        "HH:mm"
      )}`;
    },
    /**
     * Verifie si c'est une cité nouvellement ajoutée
     */
    isNew() {
      return dayjs().diff(this.cite.createdAt, "hour") <= 1;
    },
    canUpdateCite() {
      return this.hasAccess("edit_cite");
    },
    canDeleteCite() {
      return this.hasAccess("delete_cite");
    },
    canSeeDetailsCite() {
      return this.hasAccess("details_cite");
    },
  },
  methods: {
    /**
     * Affiche la modale avec les details de l'element
     */
    showDetails() {
      if (this.isSub) {
        return false;
      }
      this.$emit("showDetails", this.cite);
    },
    /**
     * Suppression du cité
     */
    remove() {
      App.confirm(
        `Voulez-vous vraiment supprimer la cité " <b>${this.cite.nomCite}</b> " ?`,
        {
          confirm: () => {
            axios.delete(`cites/${this.cite.idCite}`).then((response) => {
              if (!response.success) {
                return App.notifyError(response.message);
              }
              this.$emit("deleted", this.cite.idCite);
              return App.notifySuccess(response.message);
            });
          },
        }
      );
    },
  },
};
</script>

<style scoped>
.nombre-cite {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
}
.cite-item {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 23rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.03);
}
.cite-item .cite-img {
  padding: 5px;
  position: relative;
  width: 100%;
  height: 50%;
  border-radius: 8px 8px 0 0;
}
.cite-img .nbr-batiment {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 130px;
  height: 45px;
  border-radius: 8px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 0.9rem;
  display: none;
}
.cite-item .cite-img img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}
.cite-item .cite-info {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50%;
  width: 100%;
}
.cite-info .txt {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  width: 100%;
}
.txt .cite-title {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  /* color: #f5365c; */
  text-transform: lowercase;
}
.txt .cite-title::first-letter {
  text-transform: uppercase;
}
.txt .cite-ref {
  font-size: 0.9rem;
  color: #5c5c5c;
}
.cite-info .cite-action {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}
.cite-action span {
  font-size: 0.9rem;
  color: #5c5c5c;
}
.cite-action span.possession {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cite-action .btns {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 40px;
}
.cite-action .barre {
  margin: 0 5px;
  height: 80%;
  width: 2px;
  background: #efefef;
}
.cite-action button {
  background: transparent;
  border: none;
}
.cite-action button i {
  font-size: 1rem;
}
</style>
